<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改客户信息' : '新建客户信息'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="customerForm"
      :rules="customerRules"
      :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="手机号" name="phone">
            <a-input
              placeholder="请输入手机号"
              disabled
              v-model:value="customerForm.phone"
              allow-clear
            ></a-input>
          </a-form-item>
          <a-form-item label="真实姓名" name="realName">
            <a-input
              placeholder="请输入手机号"
              v-model:value="customerForm.realName"
              allow-clear
            ></a-input>
          </a-form-item>
          <a-form-item label="身份证号码" name="idCard">
            <a-input
              placeholder="请输入身份证号码"
              v-model:value="customerForm.idCard"
              allow-clear
            ></a-input>
          </a-form-item>
          <a-form-item label="所属运营商" name="operatorId">
            <a-select
              v-model:value="customerForm.operatorId"
              placeholder="请选择所属运营商"
               @change="changeOperator"
            >
              <a-select-option
                v-for="item in operatorList"
                :key="item.operatorId"
                :value="item.operatorId"
              >
                {{ item.operatorName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="所属租赁点" name="pointId">
            <a-select
              v-model:value="customerForm.pointId"
              placeholder="请选择租赁点"
            >
              <a-select-option
                v-for="item in pointList"
                :key="item.pointId"
                :value="item.pointId"
              >
                {{ item.pointName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="签约状态" name="contracted">
            <a-select
              v-model:value="customerForm.contracted"
              placeholder="请选择所属运营商"
            >
              <a-select-option :value="0"> 未签约 </a-select-option>
              <a-select-option :value="1"> 已签约 </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
// import * as batteryModelApi from '@/api/rnt/batteryModel'
// import * as operatorApi from '@/api/rnt/operator'
import * as operatorApi from '@/api/rnt/operator'
import * as pointApi from '@/api/rnt/point.js'
import * as customerApi from '@/api/rnt/customer.js'
export default {
  name: 'batteryModelEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      operatorList: [],
      pointList: [],
      // 表单数据
      customerForm: Object.assign({}, this.data, { model: '' }),
      // 编辑弹窗表单验证规则
      customerRules: {
        realName: [
          {
            required: true,
            message: '请输入真实姓名',
            trigger: 'blur',
            type: 'string'
          }
        ],
        operatorId: [
          {
            required: true,
            message: '请选择所属运营商',
            trigger: 'blur',
            type: 'number'
          }
        ],
        pointId: [
          {
            required: true,
            message: '请选择所属租赁点',
            trigger: 'blur',
            type: 'number'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 项目列表
      modules: [],
      // 电池品牌
      brandList: [],
      // 型号
      modelList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        // console.log('data', this.data)
        this.customerForm = this.data
        this.isUpdate = true
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    // this.queryOperatorList()
    // this.getBrandList()
    // this.getModelList()
    this.queryPoint()
    this.queryOperater()
  },
  methods: {
    changeOperator() {
      this.customerForm.pointId = null
      pointApi
        .page({ operatorId: this.customerForm.operatorId })
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          // this.form.createUserId = this.$store.state.user.user.userId
          // console.log('form', this.form)
          customerApi
            .update(this.customerForm)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 查询所属租赁点 */
    queryPoint() {
      pointApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询运营商 */
    queryOperater() {
      operatorApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>
<style scoped lang='less'>
.inputNumber {
  width: 100%;
}
</style>
