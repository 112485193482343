import request from '@/utils/request'

const api = {
  all: '/rnt/client',
  // save: '/rnt/client',
  update: '/rnt/client/update',
  //   delete: '/rnt/client/',
  // deleteBatch: '/rnt/client/batch',
  page: '/rnt/client/page',
  balance: '/rnt/client/balance',
  giftAmount: '/rnt/client/giftAmout',
  deposit: '/rnt/client/deposit',
  phone: '/rnt/client/phone',
  unfreezingDeposit: '/rnt/client/unfreezingDeposit',
  export: '/rnt/client/export'

}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    request.get(api.all, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 余额充值
 * @param data 参数
 * @returns {Promise<>}
 */
export function balance(data) {
  return new Promise((resolve, reject) => {
    request.post(api.balance, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 押金充值
 * @param data 参数
 * @returns {Promise<>}
 */
export function deposit(data) {
  return new Promise((resolve, reject) => {
    request.post(api.deposit, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 押金解冻
 * @param data 参数
 * @returns {Promise<>}
 */
export function unfreezingDeposit(data) {
  return new Promise((resolve, reject) => {
    request.post(api.unfreezingDeposit, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 赠送余额充值
 * @param data 参数
 * @returns {Promise<>}
 */
export function giftAmount(data) {
  return new Promise((resolve, reject) => {
    request.post(api.giftAmount, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 修改手机
 * @param data 参数
 * @returns {Promise<>}
 */
export function phone(data) {
  return new Promise((resolve, reject) => {
    request.post(api.phone, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 修改用户
 * @param data 参数
 * @returns {Promise<>}
 */
export function update(data) {
  return new Promise((resolve, reject) => {
    request.post(api.update, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 导出客户信息
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function exportClient(params) {
  return new Promise((resolve, reject) => {
    request.get(api.export, {
      params: params,
      responseType: 'blob'
    }).then(res => {
      resolve(res)
    }).catch(e => {
      reject(e)
    })
  })
}
